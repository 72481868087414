<template>
    <div class="live-page">
        <div class="top">
            <div class="crumbs">
                <i>
                    <img src="../assets/img/loginIcon/index-icon.png" alt="">
                </i>
                <a href="javascript:;">首页</a>
                <p>></p>
                <a href="javascript:;">直播中心</a>
                <p>></p>
                <a href="javascript:;" class="clickActive">直播列表</a>
            </div>
        </div>
        <!-- 最近直播 -->
        <div class="live-lately-box" v-if="ongoingList != ''">
            <div class="title">
                <p>正在直播</p>
            </div>
            <div class="tabBox">
                <div class="imgBox" v-show="fixed">
                    <div class="titleBox">
                        {{fixDetail.title}}
                    </div>
                    <p class="titleBox">课程精讲</p>
                    <p class="classTitle">上课时间：{{fixDetail.startTime}}</p>
                    <router-link class="jumpButton" :to="{name: 'livedetail',query:{liveId: fixDetail.id}}">
                        点击跳转
                    </router-link>
                </div>
                <div class="imgBox" v-show="dynamic">
                    <div class="titleBox">
                        {{jumpDetail.title}}
                    </div>
                    <p class="titleBox">课程精讲</p>
                    <p class="classTitle">上课时间：{{jumpDetail.startTime}}</p>
                    <router-link class="jumpButton" :to="{name: 'livedetail',query:{liveId: jumpDetail.id}}">
                        点击跳转
                    </router-link>
                </div>
                <div class="textBox">
                    <div class="textLine"></div>
                    <ul class="textUl">
                        <li v-for="(item,index) in ongoingList" :key="index">
                            <div class="slideBox" :class="{slideColor:jumpSlide == index}" @click="jumpGet(item,index)" @mouseover="jumpGet(item,index)">
                                <div class="point" :class="{pointColor:jumpSlide == index}"></div>
                                <div class="timeDate" :class="{textColor:jumpSlide == index}">
                                    <p>{{item.validEndDate}} {{item.startTime}}</p>
                                </div>
                                <div class="titleBox">
                                    <div class="subBox" :class="{subBoxColor: jumpSlide == index}">
                                        <p>{{item.category}}</p>
                                    </div>
                                    <div class="textSlide" :class="{textColor:jumpSlide == index}">
                                        <p>{{item.title}}</p>
                                    </div>
                                </div>
                                <div class="teacherBox" :class="{textColor:jumpSlide == index}">
                                    <p>主讲：{{item.teacherName}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="live-lately-box" v-else>
            <div class="title">
                <p>暂无直播</p>
            </div>
        </div>
        <div class="live-list">
            <div class="liveListBox">
                <div class="title">
                    <p>即将开始</p>
                </div>
                <ul>
                    <li v-for="(item,index) in liveList" :key="index">
                        <div class="liveSlide">
                            <div class="cover">
                                <img :src="item.logoUrl" alt="" v-if="item.logoUrl != null||item.logoUrl != undefined">
                                <img src="../assets/img/default_csmall.jpg" alt="">
                            </div>
                            <div class="textBox">
                                <div class="textTitle">
                                    <p class="textTop">
                                        {{item.title}}
                                    </p>
                                    <p class="textSub">
                                        即将直播：{{item.validEndDate}} {{item.startTime}}
                                    </p>
                                </div>
                                <div class="textBottomBox">
                                    <!-- <p><span class="price">¥5800.00</span><span class="allPrice">¥8000.00</span></p> -->
                                    <p class="link">
                                        <router-link :to="{name: 'livedetail',query:{liveId: item.id}}">
                                            即将开始
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'live',
    data() {
        return {
            jumpSlide: 0,
            liveList: [],
            ongoingList: [],
            jumpDetail: {},
            fixDetail: {},
            fixed: true,
            dynamic: false,
        }
    },
    mounted() {
        this.getliveData();
    },
    // meta标题
    metaInfo: {
        title: '直播中心-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        jumpGet(val,index) {
            this.fixed = false;
            this.dynamic = true;
            this.jumpDetail = val;
            this.jumpSlide = index;
        },
        getliveData() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/live/list.html'
            }).then((res) => {
                this.liveList = res.data.result.unstart;
                this.ongoingList = res.data.result.ongoing;
                for(let i = 0;this.ongoingList.length;i++) {
                    this.fixDetail = this.ongoingList[0];
                    return
                }
            })
        }
    }
}
</script>
<style lang="scss">
    .textColor {
        color: #FF5A74 !important;
    }
    .live-page {
        width: 100%;
        min-width: 1200px;
        background: #EEEEEE;
        padding-top: 88px;
        padding-bottom: 190px;
        box-sizing: border-box;
        .top {
            margin: 0 auto;
            margin-bottom: 58px;
            width: 1200px;
            .crumbs {
                display: flex;
                align-items: center;
                i {
                    display: inline-block;
                    margin-right: 5px;
                    width: 14px;
                    height: 14px;
                }
                p {
                    margin-left: 5px;
                    margin-right: 5px;
                }
                a {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    display: inline-block;
                    color: #666666;
                }
                .clickActive {
                    color: #FF5A74;
                }
            }
        }
        // 最近直播
        .live-lately-box {
            margin: 0 auto;
            width: 1200px;
            .title {
                margin-bottom: 20px;
                text-align: center;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }
            .tabBox {
                margin-bottom: 70px;
                display: flex;
                .imgBox {
                    padding-top: 54px;
                    box-sizing: border-box;
                    width: 600px;
                    height: 336px;
                    background: #F5F5F5;
                    text-align: center;
                    .titleBox {
                        font-size: 30px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333;
                    }
                    .classTitle {
                        margin-top: 35px;
                        margin-bottom: 30px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333;
                    }
                    .jumpButton {
                        margin: 0 auto;
                        display: block;
                        width: 154px;
                        height: 44px;
                        line-height: 44px;
                        text-align: center;
                        background: #FFFFFF;
                        border-radius: 22px;
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FF5B3C;
                    }
                }
                .textBox {
                    position: relative;
                    width: 600px;
                    height: 336px;
                    background: #FAFAFA;
                    .textLine {
                        position: absolute;
                        top: 24px;
                        left: 22px;
                        z-index: 1;
                        width: 1px;
                        height: 312px;
                        background: #DEDEDE
                    }
                    .textUl {
                        overflow-y: visible;
                        .slideColor {
                            background: #FFE2E7;
                        }
                        .slideBox {
                            position: relative;
                            padding-left: 60px;
                            box-sizing: border-box;
                            width: 100%;
                            height: 56px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            .point {
                                position: absolute;
                                top: 23px;
                                left: 18px;
                                z-index: 2;
                                width: 9px;
                                height: 9px;
                                background: #CCCCCC;
                                border-radius: 50%;
                            }
                            .pointColor {
                                background: #FF5A74;
                            }
                            .timeDate {
                                margin-right: 58px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                            }
                            .titleBox {
                                display: flex;
                                align-items: baseline;
                                .subBox {
                                    margin-right: 10px;
                                    padding: 2px;
                                    background: #BBBBBB;
                                    box-sizing: border-box;
                                    font-size: 12px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                }
                                .subBoxColor {
                                    background: #FF5A74;
                                    color: #FFFFFF;
                                }
                            }
                            .textSlide {
                                margin-right: 57px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                            }
                            .teacherBox {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                            }
                        }
                    }
                }
                
            }
        }
        .live-list {
            .liveListBox {
                margin: 0 auto;
                width: 1200px;
                .title {
                    margin-bottom: 73px;
                    text-align: center;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        margin-right: 26px;
                        width: 280px;
                        height: 281px;
                        border-radius: 6px;
                        &:last-child {
                            margin-right: 0;
                        }
                        .cover {
                            width: 280px;
                            height: 150px;
                            border-radius: 6px 6px 0px 0px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .textBox {
                            width: 280px;
                            height: 131px;
                            padding: 20px;
                            box-sizing: border-box;
                            background: #ffffff;
                            .textTitle {
                                margin-bottom: 28px;
                                .textTop {
                                    margin-bottom: 8px;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                                .textSub {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                }
                            }
                            .textBottomBox {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-end;
                                p {
                                    .price {
                                        margin-right: 9px;
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #FA4B66;
                                    }
                                    .allPrice {
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        text-decoration: line-through;
                                        color: #999999;
                                    }
                                }
                                .link {
                                    a {
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #625B5C;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>